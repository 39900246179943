.Get-Box{
width:350px;
height: 450px;
padding: 0;
}
@media screen and (max-width: 769px) {
    .get-div{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .why3-head {
        padding: 3vw 0;
    }
}
@media screen and (min-width: 1600px) {
    .why3{
        padding: 4vw 0;
    }
    .why3-head {
        padding: 3vw 0;
    }
}

@media (min-width: 2450px) {
    .why3{
       padding-top: 4vw;
    }
    .why3-head {
        font-size:3vw;
        padding: 2vw 0;
    }
    .get-div
.get-div h1{
    font-size: 1.1vw;
    line-height: 1.5vw;
}

}