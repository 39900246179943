@media (max-width: 1024px) { 
    .Sec7{
    height: auto;
  }
  .Char-pic{
    position: absolute;
   padding: 0;
   padding-left: 15px;
   margin: 0;
   left: 20%;
    width: 50%;
    height: 50%;
    bottom: 0;
  }
  .Sec7-content{
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     padding-bottom: 0;
     margin-bottom: 0;
     width: 100%;
  }
.Content1{
  width: 100%;
  padding: 0;
  padding-left: 4vw;
  margin: 0;
  margin-top: 8vw;
}
.Cont1-h{
  font-size: 3vw;
}
.Cont1-p{
  font-size: 2vw;
}
  .Sec7-content2{
    margin: 0;
    padding-right: 1vw;
    padding-left: 33vw;
    display: flex;
     flex-direction: column;
     align-items: end;
     justify-content: end;
    height: auto;
    width: 100%;
    position: relative;
    left: 0;
    text-align: right;
  }
  .Content2{
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    padding: 0;
  margin: 0;
  }
  .Sec7-content2 .Cont2-h{
    
    margin-left: 2px;
    margin-right: 0;
  }
  .Sec7-content2 .Cont2-p{
   
    margin-left: 24px;
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .Sec7{
    padding-bottom: 0;
  }
  .Sec7-content{
    display: flex;
    padding-bottom: 0;
  }
  .Content1{
    width: 100%;
    padding-left: 4vw;
    margin: 0;
  }
  .Char-pic{
    position: absolute;
    bottom: 0;
    padding: 0;
    margin: 0;
    height: auto;
  }
  .Sec7-content2{
    margin-top: 12vw;
    position: relative;
    margin-right: 3vw;
    padding-bottom: 0;
  }
  .Content2{
    margin-right: 3vw;
    
  }
}
@media (max-width: 550px) { 
  .Sec7{
    height: auto;
  }
  .Sec7-head{
   margin-top: 3vw;
  }
.Sec7-content{
  margin-top: 0;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   padding-bottom: 0;
   margin-bottom: 0;
   width: 100%;
}
.Char-pic{
  position: absolute;
  bottom: 0;
 padding: 0;
 padding-left: 15px;
 margin: 0;
 left: 20%;
  width: 55%;
  height: auto;
}
.Content1{
width: 100%;
margin-top: 0vw;
padding: 0;
padding-left: 4vw;
margin: 0;
}
.Cont1-h{
  font-size: 3vw;
  padding-bottom: 0.5vw;
}
.Cont1-p{
  padding-top: 0;
  padding-bottom: 0;
  font-size: 2.5vw;
  line-height: 5vw;
}
.Sec7-content2{
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 8vw;
  padding-right: 1vw;
  padding-left: 42vw;
  display: flex;
   flex-direction: column;
   align-items: end;
   justify-content: end;
  height: auto;
  width: 100%;
  position: relative;
  left: 0;
  text-align: right;
}
.Content2{
   padding-top: 5vw;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  /* padding: 0; */
}
.Sec7-content2 .Cont2-h{
  font-size: 4vw;
  margin-left: 2px;
  margin-right: 0;
}
.Sec7-content2 .Cont2-p{
 font-size: 2.5vw;
  margin-left: 24px;
  margin-right: 0;
}
}
@media (max-width: 378px) { 
  .Sec7{
    height: 100%;
    padding-bottom: 0;
  }
  .Sec7-head{
   margin: 3vw 0;
   padding: 0;
  }
.Sec7-content{
  margin-top: 0;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   padding-top: 0;
   padding-bottom: 0;
   margin-bottom: 0;
   width: 100%;
}
.Char-pic{
  position: absolute;
  bottom: 0;
 padding: 0;
 padding-left: 15px;
 margin: 0;
 left: 16%;
  width: 44%;
  height: auto;
}
.Content1{
width: 100%;
/* padding: 0; */
padding-top: 2vw;
padding-left: 4vw;
margin: 0;
text-wrap: wrap;
}
.Cont1-h{
  margin: 0;
  font-size: 5vw;
  padding-bottom: 0.5vw;
}
.Cont1-p{
  padding-top: 0;
  padding-bottom: 0;
  font-size: 2.5vw;
  line-height: 4.5vw;
  margin-right: 14vw;
}
.Sec7-content2{
  margin-top: 2vw;
  margin-bottom: 0;
  padding-top: 15vw;
  padding-right: 1vw;
  padding-left: 22vw;
  display: flex;
  flex-wrap: wrap;
   flex-direction: column;
   align-items: self-start;
   justify-content: start;
  height: auto;
  width: 100%;
  position: relative;
  left: 0;
  text-align: right;
}
.Content2{
  padding: 0;
}
 .Cont2-h{
  text-wrap: wrap;
  font-size: 5vw;
  margin-top: 0;
  padding-bottom: 0;
 padding-left: 7vw;
  margin-right: 0;
}
.Sec7-content2 .Cont2-p{
 font-size: 2.5vw;
  margin-left: 10vw;
  margin-right: 0;
  text-wrap: wrap;
}
}
@media (min-width: 1850px) {
.Sec7-head{
  font-size: 3vw;
}
.Sec7-content{
  text-wrap: wrap;
  width: auto;
}
.Char-pic{
  width: 23vw;
  left: 47%;
}
.Content1{
  text-wrap: wrap;
  padding: 0;
  padding-top: 1vw;
  padding-left: 2vw;
  padding-right: 2vw;
}
.Content2{
  padding-left: 3vw;
  padding-right: 2vw;
  margin: 0;
  text-wrap: wrap;
}
.Cont1-h{
  font-size: 1.7vw;
  
}
.Cont2-h{
  font-size: 1.7vw;
}
.Cont1-p{
  text-wrap: wrap;
  font-size: 1vw;
  line-height: 2.2vw;
}
.Sec7-content2{
  margin-right: 1.5rem;
  padding: 0;
  padding-left: 3vw;
  padding-bottom: 6vw;
  text-wrap: wrap;
}
.Cont2-p{
  text-wrap: wrap;
  font-size: 1vw;
  line-height: 2.2vw;
}
}

@media (min-width: 2450px) {
  .Char-pic{
    width: 15vw;
    left: 47%;
  }
}