.Box1 {
    position: relative;
    width: 350px;
    height: 450px;
    border: 2px solid white; /* Start with transparent border */
    transition: border-color 0.2s ease; /* Add transition for border color */
  }
  .Box1 {
   
    position: relative;
    display: inline-block; 
    cursor: pointer; 
    transition: color 0.25s;
  }
  
  .Box1:before,
  .Box1:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .works:before,
  .works:after {
    width: 0;
    height: 0;
    border: 2px solid transparent;
  }
  
  .works:before {
    top: 0;
    left: 0;
  }
  
  .works:after {
    bottom: 0;
    right: 0;
  }
  
  .works:hover {
    border: none;
    color: #60daaa;
  }
  
  .works:hover:before,
  .works:hover:after {
    width: 100%;
    height: 100%;
  }
  
  .works:hover:before {
    border-top-color: rgb(0, 217, 255);
    border-right-color: rgb(0, 217, 255);
    transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
  }
  
  .works:hover:after {
    border-bottom-color: rgb(0, 217, 255);
    border-left-color: rgb(0, 217, 255);
    transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s;
  }
  
  .ways:hover {
    color: rgb(0, 217, 255);
    transform: translateY(-12px)
  }
  
  .ways:after {
    top: 0;
    left: 0;
  }
  
  .ways:hover:before {
    border-top-color: rgb(0, 217, 255);
    border-right-color: rgb(0, 217, 255);
  }
  
  .ways:hover:after {
    border-bottom-color: rgb(0, 217, 255);
    border-left-color: rgb(0, 217, 255);
    transition: height 0.25s ease-out, width 0.25s ease-out 0.25s;
  }
  .ways img{
    filter: brightness(0) invert(1);
    transition: filter 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
   .ways:hover img{
    filter: brightness(1) invert(0);
  animation: rotation 1s ease;
   }
   

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(0);
    }
    
    100% {
      transform: scale(1, 1);
    }
  }
  @media (max-width:1200px) {
   .Why2{
    display: flex;
    flex-direction: column;
   }
  }

  @media screen and (max-width: 450px) {
    .Box1 {
      position: relative;
      width: 300px;
      height: 450px;
      border: 2px solid white; /* Start with transparent border */
      transition: border-color 0.2s ease; /* Add transition for border color */
    }
}
@media screen and (min-width: 1600px) {
  .why2-head{
    padding-top: 4vw;
    font-size: 3vw;
  }
  .Why2{
    padding-top: 5vw;
  }
}