.child {
    scroll-snap-align: center;
  }
  .home-sec1{
    display: flex;
  
    align-items: center;
    justify-content: space-around;
    height: 80dvh;
  }
  .Sec1-left {
width: auto;
  }
  .Sec1-h{
    font-size: 4.5vw;
    line-height: 6vw;
}
.Sec1-h1{
    padding-left: 1.2vw;
}
.Sec1h3{
    font-size: 4.5vw;
    line-height: 8vw;
}
.Sec1-h2{
    margin-top: -0.5vw;
}
.Sec1-h3{
    
    padding-left: 1vw;
    margin-top: -2rem;
}
.quote p{
 font-size: 1.5vw;
 margin: 0vw 0 ;
}
.home-button {
    margin: 0.8vw 0;
    padding: 1.3vw 3vw;
}
.home-button p{
    width: 12vw;
    font-size: 1.2vw;
}

@media (max-width:1599px) {
    .Sec1-rght{
        max-width: 750px;
        margin: 0 0  0 3vw ;
    }
    .home-pic {
        width: 90%;
    }
}
@media (max-width:1024px) {
    .home-sec1{
        display: flex;
       flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 80dvh;
      }
      .Sec1-rght{
        max-width: 450px;
        margin: 0 0  0 3vw ;
    }
    .home-pic {
        width: 100%;
    }
}
@media (max-width:860px) {
    .home-sec1{
        display: flex;
       flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }
    .Sec1-h{
        font-size: 8vw;
        line-height: 12vw;
    }
    .Sec1-h1{
        padding-left: 1.2vw;
    }
    .Sec1h3{
        font-size: 8vw;
        line-height: 15vw;
    }
    .Sec1-h3{
    
        padding-left: 1vw;
        margin-top: -1rem;
    }
    .quote p{
        font-size: 2vw;
        margin: 5px 0 ;
       }
       .home-button p{
        width: 18vw;
        font-size: 2vw;
    }
    .Sec1-rght{
        max-width: 650px;
        margin: 0 0  0 3vw ;
    }
    .home-pic {
        width: 100%;
    }
}
@media (max-width:768px) {
    .home-pic {
        width: 80%;
    }
}
@media (max-width:490px) {
    .home-sec1{
        margin: 0;
        padding-top: 0vw;
        height: 80vh;
    }
    
    .Sec1-h{
        font-size: 10vw;
        line-height: 13vw;
    }
    .Sec1-h1{
        padding: 0  1vw ;
    }
    .Sec1h3{
        font-size: 9vw;
        line-height: 16vw;
    }
    .Sec1-h3{
    
        padding-left: 1vw;
        margin-top: 0;
    }
    .Sec1-h2{
        margin-top: -0.5vw;
    }
    .quote p{
        font-size: 2.7vw;
        margin: 5px 0 ;
       }
       .home-button p{
        width: 40vw;
        font-size: 4vw;
    }

    .Sec1-rght{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
       
        margin: 0 ;
    }
    .Sec1-img{
        width: 500px;
    }
    .home-pic {
        padding-top: 2vw;
        width: 750px;
    }
}

@media (max-width:390px) {
    .home-sec1{
        margin: 0;
        padding-top: 0vw;
        height: 90vh;
        
    }
}