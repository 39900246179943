@media screen and (max-width: 2150px) {
.About-content{
    padding: 4vw 0;
}
.About2-head{
    padding: 3vw;
    padding-bottom: 4vw;
    font-size: 3vw;
}
}
@media screen and (min-width: 2350px) {
    .About-content{
        padding: 2vw 0;
    }
    .About2-head{
        padding: 1vw;
        padding-bottom: 4vw;
        font-size: 3vw;
    }
    .About-content{
        padding-top: 0;
    }
    }
    @media (max-width:1600px) {
        .About2-head{
            padding: 0;
            font-size: 3vw;
        }
        .About-content{
            padding: 1vw 0;
        }
    }
    @media (max-width:550px) {
        .About2-head{
            font-size: 6vw;
        }
    }