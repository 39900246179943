.About-square {
    position: relative;
    margin: 0 10px;
    max-width: 700px;
    max-height: 700px;
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .About-square span:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #efcba52a;
    border: none;
    border-radius: 78% 40% 80% 40% / 65% 40%  50% 80% ;
    transition: 0.5s;
    animation: animate 6s linear infinite;
  }
  
  .About-square span:nth-child(2) {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #efcba52a;
    border: none;
    border-radius:  55% 95% 53% 67% / 51% 94% 60% 69%   ;
    transition: 0.5s;
    animation: animate 4s linear infinite;
  }
  
  
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate2 {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @media screen and (max-width: 1550px) {
    .About1{
      padding-left: 2.5vw;
      padding-top: 0;
    }
    .whos{
      padding-top: 3vw;
    }
.About1-h1{
  padding-bottom: 0.3vw;
  font-size: 3vw;
}
    .whos-p1 {
      text-align: center;
      color: white;
      font-size: 2vw;
      padding-bottom: 1vw;
      padding-left: 1vw;
    }
    .whos-p2 {
      color: white;
      padding: 0;
      padding-left: 1vw;
      padding-bottom: 0%;
      font-size: 1vw ;
      line-height: 2vw;
    }
    .About1-pic{
      margin: 0;
      padding-bottom: 2vw;
    }

 }
  @media screen and (min-width: 1700px) {
    .About1{
      padding-left: 2.5vw;
      padding-top: 3vw;
    }
.About1-h1{
  padding-top: 3vw;
  font-size: 3vw;
}
    .whos-p1 {
      text-align: center;
      color: white;
      font-size: 2vw;
      padding-bottom: 1vw;
      padding-left: 1vw;
    }
    .whos-p2 {
      /* text-align: center; */
      color: white;
      padding: 1vw 1vw;
      padding-left: 1vw;
      padding-bottom: 0%;
      font-size: 1vw ;
      line-height: 2vw;
    }
    .About-square {
      position: relative;
      margin: 0 10px;
      width: 650px;
      height: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .About1-pic{
      max-width: auto;
    }
    .About1-img{
      width: 600px;
      /* background-color: antiquewhite; */
    }
 }
 @media screen and (min-width: 2350px) {
  .About1{
    padding-left: 2.5vw;
    padding-top: 1vw;
  }
.About1-h1{
padding-top: 1vw;
font-size: 2vw;
}
  .whos-p1 {
    text-align: center;
    color: white;
    font-size: 1.5vw;
    padding-bottom: 1vw;
    padding-left: 1vw;
  }
  .whos-p2 {
    /* text-align: center; */
    color: white;
    padding: 1vw 1vw;
    padding-left: 1vw;
    padding-bottom: 0%;
    font-size: 0.8vw ;
    line-height: 1.57vw;
  }
  .About-square {
    position: relative;
    margin: 0 10px;
    width: 650px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .About1-pic{
    max-width: auto;
  }
  .About1-img{
    width: 600px;
    /* background-color: antiquewhite; */
  }
}
  @media (max-width:1024px) {
    .About1{
      display: flex;
      flex-direction: column;
      padding: 0;
    }
    .whos-p1 {
      text-align: center;
      color: white;
      font-size: 6vw;
      padding-bottom: 1vw;
    }
    .whos-p2 {
      color: white;
      padding: 1vw 0;
      padding-bottom: 0%;
      font-size: 3vw ;
      line-height: 5vw;
    }
  }
  @media (max-width:769px) {

    .About1-h1{
      padding: 0.5rem 0;
    }
    .About1{
      display: flex;
      flex-direction: column;
      padding: 0 2vw;
    }
    .whos{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.8rem;
      margin: 0;
      text-align: center;
    }
    .whos-p1{
      text-align: center;
    }
    .About1-pic{
      padding: 0.1rem 0.5rem ;
    }
    .About1-img{
      width: 280px;
    }
    
  }
  @media (max-width:550px) {
    .About1-h1{
      padding: 0.3rem 0;
      font-size: 7vw;
    }
    .whos-p1 {
      text-align: center;
      color: white;
      font-size: 5vw;
      padding-bottom: 0.5vw;
    }
    .whos-p2 {
      color: white;
      padding: 0.8vw 0;
      padding-bottom: 0%;
      font-size: 3vw ;
      line-height: 4.5vw;
    }
    .About-square {
      position: relative;
      margin: 0 10px;
      width: 320px;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .About1-img{
      width: 300px;
    }
  }
  @media (max-width:392px) {
.whos{
  padding: 0.5rem;
  /* font-size: 2rem; */
}
  }