.child {
  scroll-snap-align: center;
}

@media (min-width:1600px) {
  .Sec2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }
  .Sec2 h1{
    font-size: 3vw;
    /* padding: 3vw 0; */
  }
  .square {
    position: relative;
    margin: 0 10px;
    width: 25vw;
    height: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-design{
    width: 400px;
    padding: 0;
}
.about-para p{
  font-size: 1.2vw;
  line-height: 2.2vw;
}
}
@media (max-width: 1024px) {
  .Sec2{
    padding-top: 6vw;
  }
  .Sec2 h1{
    font-size: 6vw;
    padding-bottom: 5vw;
  }
  .Sec2-data{
    padding: 4vw 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .square {
    position: relative;
    margin: 0 10px;
    width: 450px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
    .about-design{
        width: 400px;
        padding: 0;
    }
    .about-para{
      padding: 7vw 4vw;
      padding-bottom: 0;
    }
    .about-para p{
      font-size: 3vw;
      line-height: 7vw;
      text-align: center;
  }
}
@media (max-width: 769px) {
  .Sec2-data{
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .about-para{
    padding: 0 4vw;
  }
  .about-para p{
    font-size: 3.5vw;
    line-height: 7vw;
    text-align: center;
}
.about-pic{
  padding: 3vw 0;
  width: 100%;
}
.square {
  position: relative;
  margin: 0 10px;
  width: 350px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .about-design{
      width: 300px;
      padding: 0;
  }
}

@media (max-width: 550px) {
  .Sec2{
    height: auto;
  }
  .square {
    position: relative;
    margin: 0 10px;
    width: 280px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
    .about-pic{
        width: 100%;
    }
    .about-design{
        width: 200px;
        padding: 0;
    }
    .about-para {
      padding: 1rem;
    }
    .about-para p{
        font-size: 3vw;
    }
}
@media (max-width: 392px) {
  .Sec2{
  
    padding-top: 0 ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
/* .Sec2-imgs{
  overflow: hidden;
} */
.Sec2-imgs img{
  width: 80%;
  margin: 0;
}
.about-para{
  padding: 0 8vw;
}
}
@media (max-width: 375px) {
  .about-para{
    padding: 0 10vw;
  }
}

.square {
  position: relative;
  margin: 0 10px;
  max-width: 900px;
  max-height: 900px;
  /* width: 400px;
  height: 400px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
  
  .square span:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(23, 22, 22, 0.471);
        border: none;
    border-radius: 32% 58% 69% 43% / 48% 32% 59% 55%;
    transition: 0.5s;
    animation: animate 6s linear infinite;
  }
  
  .square span:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 30, 30, 0.822);
    border: none;
    border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
    transition: 0.5s;
    animation: animate 4s linear infinite;
  }
  
  .square span:nth-child(3) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(39, 38, 38, 0.958);
    border: none;
    border-radius: 31% 45% 74% 35% / 38% 56% 51% 87%;
    transition: 0.5s;
    animation: animate2 10s linear infinite;
  }
  
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate2 {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  
