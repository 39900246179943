    /* Images css start here */
    .images_Carousel {
        position: relative;
        display: flex;
        flex-wrap: wrap;
      }
      
      .ImgSlider-container {
        position: relative;
        overflow: hidden;
      }
      .Leftarrow-btn {
        position: absolute;
        top: 50%;
        left: 0.5%;
        padding-bottom: 2px;
        transform: translateY(-50%);
        cursor: pointer;
        width: 1.5%;
        z-index: 10;
      }
      .Rightarrow-btn {
        position: absolute;
        top: 50%;
        right: 0.5%;
        padding-bottom: 2px;
        transform: translateY(-50%);
        cursor: pointer;
        width: 1.5%;
        z-index: 10;
      }

   

      .ImgSlide {
        float: right;
        display: flex;
        flex-wrap: wrap;
       z-index: 2;
       background-size: cover;
      }

      .BgImg-imageGame{
         width: 100%;
        height: 100vh;
      }
      
      .ImgSlide-imageGame1 {
        position: absolute;
        bottom: 10%;
        left:2%;
        bottom: 5;
        animation: ZoomOut 5s ease-out;
        z-index: 1;
        }
        @keyframes ZoomOut {
          0% {
            transform: scale(0.5, 0.5);
          }
          100% {
            transform: scale(1, 1);
          }
        }
             
      .ImgSlide-imageGame2 {
        position: absolute;
       bottom:50%;
       left: 5.7%;
       animation: fade ;
       animation: fadeIn 1s ease-in-out;
       animation: bannermove 10s linear infinite;
        z-index: 2;
      }
      .ImgSlide-imageGame3 {
        position: absolute;
        top:20%;
        left:34.2%;
        animation: fade ;
        animation: fadeIn 1s ease-in-out;
        z-index: 2;
        animation: bannermove 10s linear infinite;
      }
      .ImgSlide-imageGame4 {
        position: absolute;
        top:69.5%;
        left:63%;
        animation: fade ;
        animation: fadeIn 1s ease-in-out;
        z-index: 2;
        animation: bannermove 10s linear infinite;
      }
      @keyframes bannermove {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-50%);
        }
      }
      .ImgSlide-imageGame5 {
        position: absolute;
        bottom: 46%;
        right:14%;
        /* bottom: 0; */
        animation: fade ;
        animation: fadeIn 1s ease-in-out;
        animation: bannermove 10s linear infinite;
        z-index: 1;
      }
     
      .ImgSlide-imageGame6 {
        position: absolute;
        top:25%;
        left: 74%;
        bottom: 0%;
        animation: fadeIn 1s ease-in-out;
        animation: bannermove 10s linear infinite;
        z-index: 2;
      }
      .ImgSlide-imageGame7 {
        position: absolute;
        bottom: 50%;
        left: 76%;
        animation: fadeIn 1s ease-in-out;
        animation: bannermove 10s linear infinite;
        z-index: 1;
      }
      /* slide 2nd images */
      .ImgSlide-imageGame8 {
        position: absolute;
        bottom: 25%;
        left: 2%;
        animation: fadeIn 1s ease-in-out;
        animation: bannermove 20s linear infinite;
        z-index: 1;
      }
      .ImgSlide-imageGame9 {
        position: absolute;
        top: 28%;
        left: 26.6%;
        animation: fadeIn2 1s ease-in-out;
        animation: bannermove 20s linear infinite;
        z-index: 1;
      }
      .ImgSlide-imageGame10 {
        position: absolute;
        bottom: 25%;
        right: 26.6%;
        animation: fadeIn2 1s ease-in-out;
        animation: bannermove 20s linear infinite;
        z-index: 1;
      }
      .ImgSlide-imageGame11 {
        position: absolute;
        top: 28%;
        right: 2%;
        animation: fadeIn2 1s ease-in-out;
        animation: bannermove 20s linear infinite;
      }
       /* slide 3rd images */
      .ImgSlide-imageGame12 {
        position: absolute;
        bottom: 28%;
        left: 6%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame13 {
        position: absolute;
        bottom: 66%;
        right: 42%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame14 {
        position: absolute;
       top: 69%;
        left: 17%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame15 {
        position: absolute;
        bottom:  55%;
        right: 18%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame16 {
        position: absolute;
        top: 36%;
        right: 28%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame17 {
        position: absolute;
        bottom:  25%;
        right: 0.1%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame18 {
        position: absolute;
        top:  65%;
        right: 10%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame19 {
        position: absolute;
        top:  45%;
        left: 87%;
        animation: bannermove 20s linear infinite;
      }
      @keyframes fadeIn {
        0% {
          opacity: 0;
          transform: translateX(-20px);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
      @keyframes fadeIn2 {
        0% {
          opacity: 0;
          transform: translateY(-20px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
      @keyframes fadeIn3 {
        0% {
          opacity: 0;
          transform: translateY(-20px);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }

      @keyframes slideIn {
        0% {
          opacity: 0;
          transform: translateY(-20px);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
      .ImgSlideMob-content {
        color: #eeeaea;
              position: absolute;
              top: 0%;
              left: 11.5%;
              right: 3%;
              padding-top: 0rem;
              display: block;
              margin-top: 0%;
              z-index: 5;
              width: 100%;
              height:100%;
            }
            .ImgSlideMob-title {
                width: 100%;
                max-width: 50rem;
                font-size: 4.15rem;
                /* line-height: 0.8rem; */
                font-weight:600;
                text-transform: capitalize;
                display: inline;
                animation: slideIn 3s ease-in-out;
            }
            .ImgSlide3-content {
              color: #020202;
                    position: absolute;
                    top: 0%;
                    left: 7%;
                    margin-top: -1.3%;
                    z-index: 5;
                    width: 25%;
                    height: 30%;
                  }
                  .ImgSlide3-title {
                    width: 100%;
                    max-width: 50rem;
                    font-size: 3rem;
                    line-height: 3rem;
                    font-weight: 700;
                    text-transform: capitalize;
                    animation: slideIn 6s ease-in-out;
                  }
      .OtherText {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        z-index: 1;
      }

      .OtherText-Content {
        font-size: 3rem;
        line-height: 20px;
        color: #fff;
      }

      @media (max-width: 600px) {
        .ImgSlide-imageGame {
          position: absolute;
          top: 40%;
          right: 30%;
          width: 38vh;
          bottom: 0;
          height: 100vw;
          animation: fadeIn 5s ease-in-out;
          z-index: 3;
        }
        .ImgSlide-imageGame2 {
          position: absolute;
          top: 33%;
          right: 1%;
          width: 55vh;
          height: 100vw;
          animation: fadeIn 1s ease-in-out;
          z-index: 2;
        }
        .ImgSlide-content {
          color: #eeeaea;
                position: absolute;
                left: 0%;
                right: 18%;
                top:1%;
                margin-top: 1%;
                margin-left: 7%;
                margin-right: 5%;
                z-index: 5;
                width: 50vh;
                height: 60%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 1rem;
                text-align: center;
                padding: 1rem;
                animation: slideIn 6s ease-in-out;
              }
              .ImgSlide-desc {
               margin-top: -8%;
               width: 100%;
               max-width: 100%;
               font-size: 15px;
               font-weight: 500;
               animation: slideIn 7s ease-in-out;
              }
      }
      @keyframes fade {
        from {
          opacity: 0.8;
        }
        to {
          opacity: 1;
        }
      }
      @media screen and (max-width: 1300px) {
        .ImgSlide-imageGame2 {
          position: absolute;
          width: 40%;
        }
        .ImgSlide-imageGame3 {
          position: absolute;
          width: 40%;
          top:27%;
          left:35%;
          animation: fade ;
          animation: fadeIn 1s ease-in-out;
          z-index: 2;
          animation: bannermove 10s linear infinite;
        }
        .ImgSlide-imageGame4 {
          position: absolute;
          width: 40%;
          top:68%;
          left:65%;
          animation: fade ;
          animation: fadeIn 1s ease-in-out;
          z-index: 2;
          animation: bannermove 10s linear infinite;
        }
        .ImgSlide-imageGame5 {
          position: absolute;
          width: 40%;
        }
        .ImgSlide-imageGame6 {
          position: absolute;
          top:31%;
          width: 40%;
          left: 74%;
          bottom: 0%;
          animation: fadeIn 1s ease-in-out;
          animation: bannermove 10s linear infinite;
          z-index: 2;
        }
        .ImgSlide-imageGame7{
          width: 40%;
        }
        .ImgSlide-imageGame8, 
        .ImgSlide-imageGame9,
        .ImgSlide-imageGame10,
        .ImgSlide-imageGame11{
          width: 20%;
        }
        .ImgSlide-imageGame12, 
        .ImgSlide-imageGame13,
        .ImgSlide-imageGame14,
        .ImgSlide-imageGame15,
        .ImgSlide-imageGame16{
          width:25%;
        }
        .ImgSlide-imageGame16 {
          position: absolute;
          top: 45%;
          right: 28%;
          animation: bannermove 20s linear infinite;
        }
        .ImgSlide-imageGame17 {
          position: absolute;
          top: 5%;
          width: 25%;
          right: 10%;
          animation: bannermove 20s linear infinite;
        }
        .ImgSlide-imageGame18 {
          position: absolute;
          top:  60%;
          width: 25%;
          right:16%;
          animation: bannermove 20s linear infinite;
        }
        .ImgSlide-imageGame19 {
          position: absolute;
          top:  45%;
          width: 25%;
          left: 90%;
          animation: bannermove 20s linear infinite;
        }
      }
      @media screen and (max-width: 1480px) {
        .ImgSlide-imageGame3 {
          position: absolute;
          top:27%;
          left:35%;
          animation: fade ;
          animation: fadeIn 1s ease-in-out;
          z-index: 2;
          animation: bannermove 10s linear infinite;
        }
        .ImgSlide-imageGame4 {
          position: absolute;
          top:68%;
          left:65%;
          animation: fade ;
          animation: fadeIn 1s ease-in-out;
          z-index: 2;
          animation: bannermove 10s linear infinite;
        }
        .ImgSlide-imageGame6 {
          position: absolute;
          top:31%;
          left: 74%;
          bottom: 0%;
          animation: fadeIn 1s ease-in-out;
          animation: bannermove 10s linear infinite;
          z-index: 2;
        }
        .ImgSlide-imageGame17 {
          position: absolute;
          bottom:  35%;
          right: 0%;
          animation: bannermove 20s linear infinite;
        }
        .ImgSlide-imageGame18 {
          position: absolute;
          top:  60%;
          right:10%;
          animation: bannermove 20s linear infinite;
        }
        .ImgSlide-imageGame19 {
          position: absolute;
          top:  45%;
          left: 86%;
          animation: bannermove 20s linear infinite;
        }
      }

      @media screen and (min-width: 1600px) {
        .ImgSlide-imageGame2 {
          position: absolute;
         bottom:49%;
         left: 5.7%;
         animation: fade ;
         animation: fadeIn 1s ease-in-out;
         animation: bannermove 10s linear infinite;
          z-index: 2;
        }
      .ImgSlide-imageGame3 {
        position: absolute;
        top:34%;
        left:31%;
        animation: fade ;
        animation: fadeIn 1s ease-in-out;
        z-index: 2;
        animation: bannermove 10s linear infinite;
      }
      .ImgSlide-imageGame4 {
        position: absolute;
        top:74%;
        left:56%;
        animation: fade ;
        animation: fadeIn 1s ease-in-out;
        z-index: 2;
        animation: bannermove 10s linear infinite;
      }
      .ImgSlide-imageGame5 {
        position: absolute;
        bottom: 45%;
        right:27%;
        /* bottom: 0; */
        animation: fade ;
        animation: fadeIn 1s ease-in-out;
        animation: bannermove 10s linear infinite;
        z-index: 1;
      }
     
      .ImgSlide-imageGame6 {
        position: absolute;
        top:35%;
        left: 65%;
        bottom: 0%;
        animation: fadeIn 1s ease-in-out;
        animation: bannermove 10s linear infinite;
        z-index: 2;
      }
      .ImgSlide-imageGame7 {
        position: absolute;
        bottom: 50%;
        left: 70%;
        animation: fadeIn 1s ease-in-out;
        animation: bannermove 10s linear infinite;
        z-index: 1;
      }
      .ImgSlide-imageGame12 {
        position: absolute;
        width: 28vw;
        bottom: 28%;
        left: 9%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame13 {
        position: absolute;
        bottom: 60%;
        right: 42%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame14 {
        position: absolute;
       top: 69%;
        left: 22%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame15 {
        position: absolute;
        bottom:  50%;
        right: 24%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame16 {
        position: absolute;
        top: 48%;
        right: 32%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame17 {
        position: absolute;
        bottom:  35%;
        right: 7%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame18 {
        position: absolute;
        top:  60%;
        right: 15%;
        animation: bannermove 20s linear infinite;
      }
      .ImgSlide-imageGame19 {
        position: absolute;
        top:  45%;
        left: 85%;
        animation: bannermove 20s linear infinite;
      }
    }

    @media screen and (min-width: 2350px) {
      .ImgSlide-imageGame2 {
        position: absolute;
       bottom:55%;
       left: 9%;
       animation: fade ;
       animation: fadeIn 1s ease-in-out;
       animation: bannermove 10s linear infinite;
        z-index: 2;
      }
      .ImgSlide-imageGame3 {
        position: absolute;
        top:24%;
        left:28%;
        animation: fade ;
        animation: fadeIn 1s ease-in-out;
        z-index: 2;
        animation: bannermove 10s linear infinite;
      }
      .ImgSlide-imageGame4 {
        position: absolute;
        top:70%;
        left:48%;
        animation: fade ;
        animation: fadeIn 1s ease-in-out;
        z-index: 2;
        animation: bannermove 10s linear infinite;
      }
      .ImgSlide-imageGame5 {
        position: absolute;
        bottom: 45%;
        right:32%;
        /* bottom: 0; */
        animation: fade ;
        animation: fadeIn 1s ease-in-out;
        animation: bannermove 10s linear infinite;
        z-index: 1;
      }
     
      .ImgSlide-imageGame6 {
        position: absolute;
        top:39%;
        left: 64%;
        bottom: 0%;
        animation: fadeIn 1s ease-in-out;
        animation: bannermove 10s linear infinite;
        z-index: 2;
      }
      .ImgSlide-imageGame7 {
        position: absolute;
        bottom: 50%;
        left: 70%;
        animation: fadeIn 1s ease-in-out;
        animation: bannermove 10s linear infinite;
        z-index: 1;
      }
    }