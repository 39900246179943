@media (max-width:769px) {
    .foter-detail{
        padding: 2rem 1rem 2rem 2rem;
    }
    .media-div{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
    }
    .logo-Comp{
        width: 55vw;
        height: 100%;
    }
    .logo-div{
        padding: 0;
        margin: 0;
       width: 60vw;
    }
    .follow{
        padding: 0;
    }
    .address p{
        padding-right: 2rem;
    }
    .media img{
        padding-right: 0.58rem;
    }
    .copyright{
        padding: 0 2vw;
    }
}

@media screen and (min-width: 2350px) {
    .follow p{
        font-size: 0.8vw;
        padding-bottom: 0.25vw;
    }
    .Quick h4{
        font-size: 1.2vw;
        padding-bottom: 0.5vw;
    }
    .Quick ul li{
        font-size: 0.8vw;
    }
    .address h4{
        font-size: 1.2vw;
        padding-bottom: 0.5vw;
    }
    .address p{
        font-size: 0.8vw;
    }
    .copyright p{
        font-size: 0.9vw;
    }
}