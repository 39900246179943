.marquee{
  display: flexbox;
}
.marquee-container {
overflow: hidden;
width: 100%;
opacity: 1;
height: 100px;
}


.image-wrapper img {
  margin: 0 6vw;
  transition: filter 0.3s ease;
 
}


@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}