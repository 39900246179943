.parent {
  height: 100vh;
  overflow: auto;
  padding: var(--scroll-gap);
  scroll-snap-type: y mandatory;
}

.child {
  scroll-snap-align: center;
}
html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
}

.section {
  text-align: center;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}