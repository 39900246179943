.scroll-to-top {
  position: fixed;
  bottom: 50px;
  right: 20px;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.scroll-to-top:hover {
  background-color: rgb(0, 217, 255);
}

.scroll-to-top-icon {
  width: 20px;
  height: 20px;
  fill: #ffffff;
}

/* Add media queries for responsiveness */
@media (max-width: 768px) {
  .scroll-to-top {
    margin-bottom: 11vw;
    bottom: 40px;
    right: 15px;
    width: 35px;
    height: 35px;
  }

  .scroll-to-top-icon {
    
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 480px) {
  .scroll-to-top {
    bottom: 30px;
    right: 10px;
    width: 30px;
    height: 30px;
  }

  .scroll-to-top-icon {
    width: 16px;
    height: 16px;
  }
}
