/* Default styles */


.why-head {
  text-align: center;
  font-size: 40px;
  font-weight: 900;
  color: white;
  font-family: "lato";
  padding: 2vw 0;
  /* padding-bottom: 3px; */
  margin: 0;
}

.Choose {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5vw;
}

.left {
  width: 60%;
  height: auto;
}

.left h1 {
  text-align: left;
  font-size: 75px;
  font-weight: 700;
  color: white;
  padding: 5px 0;
  padding-top: 5px;
  line-height: 12vh;
}

.left h3 {
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
  color: white;
  padding:0;
}

.left .why-btn {
  padding: 0;
}

.left button {
  margin: 1.5vw 0;
}

.rght {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vw;
}
.rght img{
  width: auto;
  height: auto;
}
@media  (min-width: 2150px) {
  .Choose{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0;
    padding-bottom: 1vw;
  } 
  .left{
    width: 50%;
    padding-left: 5vw;
    margin: 0;
  }
  .why-head{
    font-size: 2vw;
  }
  .left h1{
    font-size: 3vw;
  }
  .left h3{
    padding: 0;
    font-size: 1vw;
  }
  .why-button p{
    font-size: 1.2vw;
}
.right{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.why-square{
  width: 60vw;
  height: 60vw;
}
.why-img{
  width: 35vw;
}
}
@media screen  and (max-width: 2100px) {
.Choose{
  padding: 3vw 6vw;
  padding-bottom: 1vw;
} 
.why-head{
  padding-top: 2vw;
  font-size: 3vw;
}
  .left h1 {
    font-size: 4vw;
    line-height: 4vw;
    
  }
  .left h3{
    font-size: 1.3vw;
    padding: 3vw 0;
  }
  .why-button{
    margin-top: 18vw;
  }
  .right{
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .why-square{
    max-width: 80vw;
    max-height: 80vw;
    width: 60vw;
    height: 50vw;
  }
  .why-img{
    max-width: 45vw;
    width: 20vw;
  }
  .why-button{
    max-width: 60vw;
    width: 20vw;
    height: 4vw;
    margin: 0.5vw 1vw;
    padding: 0.2vw 2.2vw;
    text-align: center;
}
.why-button p{
    font-size: 1.2vw;
}
}
@media screen  and (max-width: 1550px) {
  .why-head{
    padding-top: 00.8vw;
    font-size: 3vw;
  }
    .left h1 {
      font-size: 4vw;
      line-height: 4vw;
      
    }
}
@media screen and (max-width: 1024px) {
  .Choose{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .left h1 {
    font-size: 4vw;
    line-height: 8vh;
    text-align: left;
  }
}
@media screen and (max-width: 769px) {
  .Choose {
    flex-direction: column;
  }
  .left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .left, .rght {
    width: 100%;
  }

  .left h1 {
    font-size: 48px;
    line-height: 8vh;
    text-align: center;
  }
  .left h3{
    text-align: center;
    font-size: 2rem;
  }

}

@media screen and (max-width: 576px) {
  .left{
    padding-bottom: 3vw;
  }
  .why-head{
    font-size: 7vw;
  }
  .left h1 {
    font-size: 36px;
    line-height: 6vh;
  }

  .left h3 {
    font-size: 1rem;
  }

  .left h6 {
    padding: 0.5vw 1vw;
  }
  .why-button{
   
    margin: 0.5vw 1vw;
}
  .why-button p{
padding-top: 0;
  }
.why-square{
  margin: 1.5vw 0;
}
  .rght {
    background-size: 48%; /* Adjust the size as needed */
    background-position: center;
  }

}
@media screen and (max-width: 450px) {
  .Choose{
    display: flex;
    justify-content: center;
  }
  .why-button{
    margin: 0.5vw 1vw;
    font-size: 1vw;
}
  .why-button .why-btn{
padding: 0;
  }

  .why-img{
    width: 200px;
  }
  .why-square{
    padding-top: 5vw;
  }
}
.why-square {
  position: relative;
  margin: 0 10px;
  max-width: 80vw;
  max-height: 80vw;
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.why-square span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #efcba52a;
  border: none;
  border-radius: 78% 40% 80% 40% / 65% 40%  50% 80% ;
  transition: 0.5s;
  animation: animate 6s linear infinite;
}

.why-square span:nth-child(2) {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #efcba52a;
  border: none;
  border-radius:  55% 95% 53% 67% / 51% 94% 60% 69%   ;
  transition: 0.5s;
  animation: animate 4s linear infinite;
}