.Sec4-content{
  margin: 0 6vw;
  padding: 3vw 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Circle1{
  padding: 3.7vw;
  margin-right: -2vw;
}
.head1{
  font-size: 2vw;
  padding: 0.8vw;
  padding-bottom: 0;
}
.head-P1{
  font-size: 1.5vw;
}
.Circle2{
  padding: 4vw;
}
.head2{
  font-size: 3vw;
  padding: 1.8vw;
  padding-bottom: 000;
}
.head-P2{
  font-size: 1.75vw;
}
.Circle3{
  padding: 6vw 5vw;
  padding-top: 4vw;
  margin: 0 -4vw;
}
.head3{
  font-size: 6vw;
}
.head-P3{
  padding: 0;
  font-size: 1.75vw;
}
.Circle4{
  padding:3.8vw;
}
.head4{
  font-size: 3vw;
  padding: 1.8vw;
  padding-bottom: 0;
}
.head-P4{
  font-size: 1.5vw;
}
.Circle5{
  padding: 3.7vw 4vw;
  padding-top: 5vw;
  margin-left: -2vw;
}
.head5{
  font-size: 2vw;
  padding: 0.8vw;
  padding-top: 1vw;
  padding-bottom: 0;
}
.head-P5{
  padding: 0;
  margin: 0;
  font-size: 1.4vw;
}
@media (min-width:1600px) {
  .Sec4{
    padding-top: 3vw;
  }
  .Sec4-content{
    margin: 5vw 6vw;
    padding: 3vw 15vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
@media (max-width:1250px) {
  .Sec4-content{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .Circle1,
  .Circle3,
  .Circle5{
    margin: 0;
  }
}
 @media (max-width:1250px) {
  .Sec4-content{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .Circle2,
  .Circle1,
  .Circle3,
  .Circle4,
  .Circle5{
    display: flex;
   
    flex-direction: column;
    justify-content: center;
    justify-content: space-between;
    align-items: center;
   margin: 0;
  }
  .Circle1{
    padding: 4vw;
  }
  .Circle2{
    padding: 8vw;
  }
  .Circle3{
    padding: 8vw;
  }
  .Circle4{
    padding: 7vw;
  }
  .Circle5{
    padding: 8vw;
  }
  .head1{
    padding: 0;
    margin: 0;
    font-size: 5vw;
    
  }
  .head-P1{
    font-size: 3vw;
  }
  .head-P2{
    font-size: 3vw;
  }
  .head2{
    padding: 0;
    margin: 0;
    font-size: 5vw;
  }
  .head3{
    font-size: 8vw;
    padding: 0;
    margin: 0;
  }
  .head-P3{
    padding: 0;
    margin: 0;
    font-size: 3vw;
  }
  .head4{
    padding: 0;
    margin: 0;
    font-size: 8vw;
  }
  .head-P4{
    padding: 0;
    margin: 0;
    font-size: 2.2vw;
  }
  .head5{
    padding: 0;
    margin: 0;
    font-size: 13vw;
  }
  .head-P5{
    padding: 0;
    margin: 0;
    font-size: 3vw;
  }
}
/* @media (max-width:1024px) {
  .Circle1{
    padding: 3vw;
  }
  .Circle2{
    padding: 5vw;
  }
  .Circle3{
    padding: 8vw;
  }
  .Circle4{
    padding: 7vw;
  }
  .Circle5{
    padding: 5vw;
  }
  .head1{
    padding: 0;
    margin: 0;
    font-size: 4vw;
    
  }
  .head-P1{
    font-size: 3vw;
  }
  .head-P2{
    font-size: 3vw;
  }
  .head2{
    padding: 0;
    margin: 0;
    font-size: 3vw;
  }
  .head3{
    font-size: 4vw;
    padding: 0;
    margin: 0;
  }
  .head-P3{
    padding: 0;
    margin: 0;
    font-size: 3vw;
  }
  .head5{
    padding: 0;
    margin: 0;
    font-size: 8vw;
  }
  .head-P5{
    padding: 0;
    margin: 0;
    font-size: 2vw;
  }
} */
@media (max-width: 768px) {
  
  .Circle1{
    margin-bottom: 0.8vw;
  }
  .Circle2{
    margin-bottom: 0.8vw;
  }
 .Circle3{
  margin-bottom: 0vw;
  padding: 12vw;
 }
 .Circle4{
  margin-top: 0;
  padding: 12vw;
 }
  .head-P3{
    padding: 0;
    margin: 0;
    font-size: 5vw;
  }
  .head-P4{
    font-size: 5vw;
  }
  .Circle5{
    padding: 9vw;
  }
  .head5{
    font-size: 11vw;
  }
  .head-P5{
    font-size: 5vw;
  }
} 
@media (max-width:450px) {
  .Sec4{
    padding: 4vw;
    padding-top: 5vw;
  }
  .Sec4-head{
    font-size: 7.5vw;
    padding-top: 0;
  }
  .Circle1{
    margin-bottom: 0.8vw;
  }
  .Circle2{
    margin-bottom: 0.8vw;
  }
 .Circle3{
  margin-bottom: 0vw;
  padding: 9.75vw;
 }
 .Circle4{
  margin-top: 0;
  padding: 13vw;
 }
  .head-P3{
    padding: 0;
    margin: 0;
    font-size: 5vw;
  }
  .head-P4{
    font-size: 5vw;
  }
  .Circle5{
    margin-top: 0;
    padding: 9vw;
  }
  .head5{
    font-size: 11vw;
  }
  .head-P5{
    font-size: 5vw;
  }
} 

@media (min-width:1800px) {
  .Sec4-head{
    font-size: 3vw;
  }
}