@media screen and (min-width: 1600px) {
    .Contact1-head {
        padding-top: 5vw;
        font-size: 3vw;
    }
    .Contact1-data{
        padding: 0 1vw;
    }
    .needs{
        padding-top: 4vw;
    }
    .needs-p1{
        font-size: 4.5vw;
    }
    .needs-p2{
        font-size: 1.2vw;
        line-height: 2vw;
    }
    
    .contact1-img{
        width: 20vw;
    }
    .About-square{
        width: 28vw;
        height: 25vw;
    }
    
}
@media screen and (min-width: 2350px) {
    .Contact1-head {
        padding-top: 2vw;
        font-size: 2vw;
    }
    .Contact1-data{
        padding: 0 1vw;
    }
    .needs{
        padding-top: 0.8vw;
    }
    .needs-p1{
        font-size: 4.5vw;
    }
    .needs-p2{
        font-size: 1.2vw;
        line-height: 2vw;
    }
    .needs .p3{
        font-size: 1vw;
    }
    .contact1-img{
        padding: 0;
        width: 18vw;
    }
    .contact-rght{
        padding-bottom: 0;
        
        height: 80%;
    }
    .About-square{
        width: 24vw;
        height: 22vw;
    }
}

@media (max-width:768px) {
    .Contact1-data{
        padding: 0.5rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .Contact1-head{
        padding: 0.8rem 0;
    }
    .needs{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0rem 0.8rem;
      padding: 1rem 1rem;
    }
    .needs-p1{
        text-align: center;
    }
    .needs-p2{
        text-align: center;
    }
    .needs p{
        padding-top: 0.3rem;
        text-align: center;
    }
    .contact1-img{
        width: 85vw;
    }
}
