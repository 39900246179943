

.slick-slide div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img-slider{
  padding: 20px;
  margin:20px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
}
.img-slider .work-imgs{
  transition: transform 400ms ease-in-out;
}

.img-slider.center .work-imgs {
  transform: perspective(800px) rotateY(22deg) rotateZ(-4deg) rotateZ(4deg) scale(1);
  z-index: 20;
  margin-left: 40px;
  margin-bottom: 0px;
}

.img-slider.rotate-left .work-imgs {
  transform: perspective(900px) rotateY(-20deg) rotateZ(-4deg) rotateZ(4deg) scale(1);
  z-index: 20;
  margin-left: -30px;
padding-bottom: 10px;
}

.img-slider.rotate-right .work-imgs {
  transform: perspective(1000px) rotateY(20deg)  scale(1.02);

}

/* Additional necessary styles */

.Img-container {
  position: relative;
}

@media (max-width: 1024px) {
  .Sec5 h1{
    font-size: 4vw;
  }
  .img-slider{
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 20px 1vw;
    
    margin:20px;
    padding-right: 0;
  
  }
  .img-slider img{
    padding: 1vw;
    max-height: 70vh;
    height: 46vh;
    margin-bottom: 2vh;
  }
}

@media (max-width: 768px) {

  .Img-container{
    display: flexbox;
    justify-content: center;
    align-items: center;
 padding-left: 0;
  }
  .slick-slide div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0
  }
  
  .img-slider{
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 20px;
    margin:20px;
    padding-right: 0;
  
  }
.img-slider img{
  height: 70vh;
  border: none;
  border-radius: 3%;
}
  
}
@media (max-width: 550px) {
  .Sec5{
    min-height: auto;
  }
  .Sec5 h1{
    font-size: 5vw;
  }
  .Img-container{
    display: flexbox;
    justify-content: center;
    align-items: center;
 padding-left: 0;
  }
  .slick-slide div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0
  }
  
  .img-slider{
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 20px;
    margin:20px;
    padding-right: 0;
  
  }
.img-slider img{
  /* height: 60vh; */
  height: auto;
  border: none;
  border-radius: 3%;
}
  
}
@media (max-width: 450px) {
  .Sec5{
    min-height: auto;
  }
  .Sec5 h1{
    font-size: 7vw;
  }
  .img-slider img{
    height: auto;
    border: none;
    border-radius: 3%;
  }

}
@media (min-width: 1850px) {
  .Sec5 h1{
    margin: 0;
    padding-top: 2vw;
    font-size: 3vw;
  }
  .img-slider{
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 4vw 10px;
    padding-top: 2vw;
    padding-bottom: 3vw;
    margin:0;
    padding-right: 0;
  max-height: 80vh;
  height: 77vh;
  }
  .img-slider img{
    padding: 1vw;
    min-height: 70vh;
    height: 46vh;
    margin-bottom: 2vh;
  }
}