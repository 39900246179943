@media (min-width:1850px){
.nav-items{
    padding-right: 9vw;
}
.nav-items ul li a{
    padding: 2vw;
    font-size: 1.3vw;
}
.nav-btn{
    font-size: 1.3vw;
}
}
.Camp-logo{
    width: 5vw;
    height: 20%;
}